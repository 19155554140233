<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()

const calcMetrs = computed(() => {
  const steps = store.getters['configurator/steps']
  if (steps) {
    const step = steps.find(s => s.model_step)
    if (step) {
      const option = step.options.find(o => o.selected)
      const val = Number.parseInt(option.value)
      const singleModule = 1.95
      return val * singleModule
    }
  }
  return 0
})
</script>
<template>
<div v-if="calcMetrs > 0" class="text-center mb-3">Ihre geplante Solaranlage beträgt ca. <strong>{{ calcMetrs }}m<sup>2</sup></strong></div>
</template>
